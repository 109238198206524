/* Place switches and their labels in one line */
#switch_label {
  display: inline-block;
}

/* Place <div> to right */
.grp_justify_right {
  justify-content: end;
  flex-direction: row-reverse;
}

.justify_left {
  float: left;
}

.justify_right {
  float: right;
}

/* Give red colour to error message */
.error {
  color: #fd625e;
  font-size: 85%;
  margin-top: 0.25rem;
  display: block;
  width: 100%;
}

/* Add margin to left */
.ml-2 {
  margin-left: 2.5rem;
}

/* Add margin to right */
.mr-2 {
  margin-right: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-0-60 {
  margin-right: 0.6rem;
}

.mr-3 {
  margin-right: 3rem;
}

/* Add margin on left and bottom */
/* .form-div-temp {
    margin-left: 275px;
    margin-bottom: 50px;
} */

/* Place elements in a row */
.ele_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Add margin on left and right side */
.pa_20 {
  margin-left: 20px;
  margin-right: 20px;
}

/* Button size 4.5 rem */
.btn_size4_5_cstm {
  width: 4.5rem;
}

.btn_size_6_cstm {
  width: 6rem;
}

.btn_size_8_cstm {
  width: 8.5rem;
}

.card-title {
  margin: 0 0 7px 0;
}
.icon_s32 {
  font-size: 32px;
}

.icon_s28 {
  font-size: 28px;
}

.icon_s23 {
  font-size: 23px;
}

.icon_s18 {
  font-size: 18px;
}

.icon_s22 {
  font-size: 22px;
}

.icon_s20 {
  font-size: 20px;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

#loCode {
  pointer-events: none;
}

#textarea {
  height: 100px;
}

.link_color_blue {
  color: blue;
}

.datetime {
  height: 10px;
}

.react-select {
  height: 2rem;
  width: 20rem;
  margin-bottom: 1rem;
  border-color: #e9e9ef;
  border-radius: 5px;
}

.react-select-items {
  font-size: 15px;
  font-weight: 400;
  font-family: IBM Plex Sans;
}

.not-visisble {
  visibility: hidden;
}

.center-justify {
  padding-left: 4.5rem;
  margin-bottom: 2rem;
}

.select_field_name {
  pointer-events: none;
}

.datepicker {
  width: 20rem;
  height: 2rem;
  padding: 15px;
  border-width: 1px;
  border-color: #a9a9a9;
  border-radius: 5px;
  font-size: medium;
  background: url(../Media/calendar.png) no-repeat;
  background-position: 96%;
  background-size: 20px;
  border-style: solid;
}

.datetimepicker {
  height: 2rem;
  padding: 15px;
  border-width: 1px;
  border-color: #a9a9a9;
  border-radius: 5px;
  background: url(../Media/date-time.png) no-repeat;
  background-position: 96%;
  background-size: 20px;
  border-style: solid;
  max-width: 12rem;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.w-7 {
  width: 7rem;
}

.w-10 {
  width: 10rem;
}

.w-12 {
  width: 12rem;
}

.w-15 {
  width: 15rem !important;
}

.w-18 {
  width: 18rem;
}

.w-20 {
  width: 20rem;
}

.wid-data {
  font-size: 60px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.wid-data-C {
  font-size: 50px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: grey;
}

.h-2 {
  height: 2.5rem;
}

.h-3 {
  height: 3rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-10 {
  margin-left: 10rem;
}

.ml-8 {
  margin-left: 6rem;
}

.btn_height {
  /* height: 4.5rem; */
  position: absolute;
}

.pos-end {
  position: absolute;
  right: 0.75rem;
}

.ele_row1 {
  display: flex;
  flex-direction: row;
}

.ml-1p {
  margin-left: 1px;
}

.ml-2p {
  margin-left: 2px;
}

.pos-start {
  display: flex;
  justify-content: flex-start;
}

.pos-start-0_75 {
  display: block;
  margin-left: 0.75rem;
}

.pos-end-8 {
  position: absolute;
  right: 8rem;
}

.pos-end1 {
  position: absolute;
  right: 7rem;
}

.pos-end-10 {
  position: absolute;
  right: 10rem;
}

.pos-end-14 {
  position: absolute;
  right: 14.5rem;
}

.pos-end12 {
  position: absolute;
  right: 12rem;
}

.pos-end-header {
  position: absolute;
  right: 7.5rem;
}

.pos-end2 {
  display: flex;
  justify-content: flex-end;
}

.pos-center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.pos-middle {
  display: block !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.img_height {
  max-height: 6rem;
}

.header_height {
  height: 3rem;
}

.navbar_height {
  height: 2.5rem;
}

.navbar_menu {
  display: grid;
  align-content: center;
}

.page_title {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: -3px;
}

.mb-8 {
  margin-bottom: 8px;
}

.select-height {
  z-index: 100 !important;
}

.btn_size_cstm {
  width: auto;
}

.asteric::after {
  content: '*';
  color: red;
}

.tabHeight {
  min-height: 25rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
}

.bold_lbl {
  font-weight: 900;
}

.btn_Margin {
  margin-bottom: 2rem;
}

#btn_size_cstm {
  width: auto;
}

.alert {
  width: 25rem;
  height: auto;
}

.switch-label {
  display: inline-block;
  width: 5rem;
}

.card-height-3-8 {
  max-height: 4.5rem;
  border-radius: 0.8rem;
  max-width: 6rem;
}

.fresh_Water_Card {
  min-width: 8rem;
  border-radius: 0.5rem;
  padding: 5px;
}

.textarea {
  min-height: 100px;
}

.w-5 {
  min-width: 25rem;
}

.ml-3 {
  margin-left: -3rem;
}

.no-word-wrap {
  white-space: nowrap;
}

.word-wrap {
  word-wrap: break-word;
}

.nopadtop {
  padding-top: 0 !important;
}

.nopadbottom {
  padding-bottom: 0 !important;
}

.nopadright {
  padding-right: 0 !important;
}

.nopadleft {
  padding-left: 0 !important;
}

.margin-16 {
  margin: 16px;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.form-control:disabled {
  background-color: #e9e9ef !important;
}

.ele_row_evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ele_row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.vessel-card-height {
  padding: 0.5rem !important;
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
}

.switch_label {
  min-width: 4rem;
}

.mt-1 {
  margin-top: 1rem;
}

.CII-rating-A {
  background-color: #058742;
  max-width: 6rem;
  border-radius: 0.8rem;
}

.CII-rating-B {
  background-color: #8bc63e;
  max-width: 6rem;
  border-radius: 0.8rem;
}

.CII-rating-C {
  background-color: #f7ee21;
  max-width: 6rem;
  border-radius: 0.8rem;
}

.CII-rating-D {
  background-color: #fbb03f !important;
  max-width: 6rem;
  border-radius: 0.8rem;
}

.CII-rating-E {
  background-color: #f3786e;
  max-width: 6rem;
  border-radius: 0.8rem;
}

.primary-label {
  color: #5156be !important;
}

.round_border {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.custom-tooltip {
  background-color: #ffffff;
  padding: 5px;
}

.extra_bold_lbl {
  font-weight: bolder;
  font-size: 17px !important;
}

.vertical-table-scroll {
  max-height: 33rem;
}

.mtop {
  margin-top: 2rem;
}

.lbl_font_15 {
  min-height: 1.5rem;
}

.opacity {
  opacity: 0.8;
}

.font-large {
  /* color: deepskyblue; */
  font-size: large;
}

.waterROBlbl {
  /* color: deepskyblue; */
  font-size: 26px;
  margin-left: 0.1rem;
}

.custom-dropzone {
  max-height: 5rem !important;
}

.nopadding {
  padding: 0 !important;
}

.font_16 {
  font-size: 16px !important;
}

.form-floating > label {
  z-index: 0 !important;
}

.CII_adjustment_file_name {
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 3px;
  margin-left: 3px;
  color: #5156be;
}

input[type='datetime-local'] {
  height: 38px;
  max-width: 165px;
}

.table {
  border-style: solid;
  border-collapse: collapse;
  border-width: 1px;
}

.submitErrorIcon {
  font-size: 75px;
  color: #4ba6ef;
  margin-bottom: 3rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ml--5 {
  margin-left: -5rem;
}

.font-smaller {
  font-size: smaller;
}

.w-3 {
  width: 3rem;
}

.mr-15 {
  margin-right: 15rem;
}

.mr-0-5 {
  margin-right: 0.5rem;
}

.w-12 {
  min-width: 12rem;
}

.ptb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pb--2 {
  padding-bottom: -2rem;
}

.pr-0 {
  padding-right: 0rem !important;
}

.vertical-scrollbar {
  overflow-y: scroll;
}

.max-h-20 {
  max-height: 20rem;
}

.max-h-7 {
  max-height: 7rem;
}

.max-h-2 {
  max-height: 2.33rem;
}

.ml-5 {
  margin-left: 6rem;
}

.draggable-vessels {
  border-style: solid;
  border-width: 0.15rem;
  border-color: #5156be;
  border-radius: 0.3rem;
  min-width: 10rem;
  padding: 0.5rem;
  /* background-color: #5156be;
  color: white; */
  font-weight: 500;
}

.min-height-15 {
  min-height: 15rem;
}

.max-width-4 {
  max-width: 4rem;
}

.max-width-5 {
  max-width: 5rem;
}

.max-width-7 {
  max-width: 7rem !important;
}

.max-width-8 {
  max-width: 8rem;
}

.max-width-9 {
  max-width: 9rem;
}

.max-width-9-5 {
  max-width: 10.1rem;
}

.max-width-10 {
  max-width: 10rem;
}

.max-width-11 {
  max-width: 11rem;
}

.max-width-12 {
  max-width: 12rem;
}

.max-width-13 {
  max-width: 13rem;
}

.max-width-14 {
  max-width: 14rem;
}

.max-width-15 {
  max-width: 15rem !important;
}

.max-width-16 {
  max-width: 16rem;
}

.max-width-20 {
  max-width: 20rem;
}

.max-width-25 {
  max-width: 25rem;
}

.scroll-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card {
    display: inline-block;
  }
}

.vessel-card {
  /* min-width: 40rem; */
  max-width: 18rem !important;
  margin-right: 2rem;
  border-radius: 1.8rem !important;
}

.scroll-container::-webkit-scrollbar {
  background: transparent;
}

.chevron {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-size: cover;
  cursor: pointer;
  font-size: 3rem;
}

.left-chevron {
  left: 0;
}

.right-chevron {
  right: 0.8rem;
}

.vessel-card-details {
  font-size: 1.05rem !important;
  font-weight: 900 !important;
}

.vessel-card-footer {
  min-width: 4.5rem !important;
  max-height: 2.5rem;
  border-radius: 0.8rem;
}

.color-black {
  color: rgb(60 60 60) !important;
  font-size: 25px;
  font-weight: 600;
}

.mt-1_2rem {
  margin-top: 1.2rem !important;
}

.pointer {
  cursor: pointer;
}

.label-w-20 {
  min-width: 20rem;
}

.fleet-tbl-rating {
  font-size: 28px;
  text-align: center;
  font-weight: bolder;
  color: white !important;
}

.fleet-tbl-rating-C {
  font-size: 28px;
  text-align: center;
  font-weight: bolder;
  color: grey !important;
}

.color-black {
  color: black !important;
}

.background-A {
  background-color: #058742 !important;
  max-width: 4rem;
  border-radius: 5px;
  /* padding-top: 0rem !important;
  padding-bottom: 0rem !important; */
}

.background-B {
  background-color: #8bc63e !important;
  max-width: 4rem;
  border-radius: 5px;
  /* padding-top: 0rem !important;
  padding-bottom: 0rem !important; */
}

.background-C {
  background-color: #f7ee21 !important;
  max-width: 4rem;
  border-radius: 5px;
  /* padding-top: 0rem !important;
  padding-bottom: 0rem !important; */
}

.background-D {
  background-color: #fbb03f !important;
  max-width: 4rem;
  border-radius: 5px;
  /* padding-top: 0rem !important;
  padding-bottom: 0rem !important; */
}

.background-E {
  background-color: #f3786e !important;
  max-width: 4rem;
  border-radius: 5px;
  /* padding-top: 0rem !important;
  padding-bottom: 0rem !important; */
}

.minHeight15 {
  min-height: 15rem;
}

.sts-timeline-box {
  min-width: 20rem !important;
}

.margin-top {
  margin-top: 1.75rem !important;
}

.tooltip {
  font-family: 'Montserrat' !important;
}

.ele_col {
  display: flex;
  flex-direction: column;
}

.label-red {
  color: #fd625e;
}

.mr-6 {
  margin-right: 6rem;
}

.mr-8 {
  margin-right: 8rem;
}

.ml-1_25 {
  margin-left: 1.25rem;
}

.mr-10 {
  margin-right: 10rem;
}

.mr-11_5 {
  margin-right: 11.5rem;
}

.vertically-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.textarea-resize-none {
  resize: none;
}

.width-max-100 {
  max-width: 100% !important;
}

.width-20 {
  width: 20% !important;
}

.width-50 {
  width: 50% !important;
}

.width-90 {
  width: 89% !important;
}

.width-100 {
  width: 100% !important;
}

.width-10 {
  max-width: 5rem !important;
}

.margin-top-1 {
  margin-top: 0.4rem !important;
}

.margin-top-0-5 {
  margin-top: 0.5rem !important;
}

.w-6 {
  width: 6rem !important;
}

.nav-menu-margin {
  margin-top: 4.5rem;
}

.company-name-margin {
  margin-left: 8.2rem;
}

.table tr:hover {
  background-color: #eee;
}

.table-gray thead {
  background-color: #e9e9ef;
  /* Custom gray color */
  color: #000;
  /* Text color */
}

.table-gray thead th {
  background-color: #e9e9ef;
  /* Ensures header cells have the same background */
}

.shore_supply_margin {
  margin-top: 0.9rem !important;
  margin-bottom: 0.9rem !important;
}

.pad-right-20 {
  padding-right: 20px !important;
}

/* .reArrow [data-popper-placement^="left"]::before {
  border-left-color: #fd625e !important;
}

.reArrow .tooltip-auto[data-popper-placement^="right"] {
  border-right-color: #fd625e !important;
}

.reArrow .tooltip-auto[data-popper-placement^="top"] {
  border-top-color: #fd625e !important;
} */

.redArrow .arrow.tooltip-arrow::before,
.redArrow .arrow.tooltip-arrow::after,
.reArrow
  .tooltip-auto[data-popper-placement^='bottom']
  .tooltip-auto[data-popper-placement^='top'] {
  border-bottom-color: #fd625e !important;
  border-top-color: #fd625e !important;
}

.sr-no-width {
  width: 5%;
  text-align: center;
}

.max-width-50 {
  max-width: 50rem;
}

.no-border-radius {
  border-radius: 0px !important;
}

.colour-grey {
  background-color: lightgrey !important;
}

.no-hover tr:hover {
  background-color: transparent !important;
}

.batch-bg-color {
  background-color: rgb(223 239 252 / 42%) !important;
}

.functional-status-color {
  background-color: #4bb96f !important;
  color: white !important;
  border-color: #4bb96f !important;
}

.engineer-card-body,
.deck-card-body {
  min-height: 19rem !important;
  padding: 0px 8px 0px 8px !important;
}

.dark_lbl {
  color: #000;
}

.dashboard-bg-light {
  background-color: #d4dae245 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.rating-height {
  height: 10rem;
}

.left-chevron {
  left: -5px !important;
}

.min-width-9-5 {
  min-width: 9.5rem !important;
}

.min-width-12 {
  min-width: 12rem;
}

.max-width-10 {
  max-width: 12rem;
}

.three-state-switch {
  width: 7rem;
  height: 1.75rem;
  background-color: #ced4da;
  border-radius: 3px;
}

.three-state-switch-toggle {
  position: absolute;
  opacity: 0;
}

.three-state-switch-toggle:checked + label {
  background-color: #5156be;
  color: white;
  min-width: 2rem;
  text-align: center;
  border-radius: 3px;
}

.cursor-arrow {
  cursor: default;
}

.coloured-bg-green {
  color: #339b42;
}

.coloured-bg-lbl-green {
  background-color: #339b42;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-blue {
  color: #2a5f86;
}

.coloured-bg-lbl-blue {
  background-color: #2a5f86;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-grey {
  color: #6e6f6c;
}

.coloured-bg-lbl-grey {
  background-color: #6e6f6c;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-black {
  color: #1b1d1f;
}

.coloured-bg-lbl-black {
  background-color: #1b1d1f;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-red {
  color: #fd625e;
}

.coloured-bg-lbl-red {
  background-color: #fd625e;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-light-grey {
  color: #e1e4e5;
}

.coloured-bg-lbl-light-grey {
  background-color: #e1e4e5;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-value {
  font-size: 18px;
  font-weight: 900;
}

.coloured-bg-div {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.green-gradient-container {
  background: linear-gradient(to bottom, #069171, #024836);
  height: 10rem;
  border-radius: 1rem !important;
}

.gradient-container-button {
  background-color: #dff391 !important;
  color: #000000 !important;
  font-weight: 700 !important;
}

.icon-bg {
  border-radius: 100%;
  background-color: #145d92;
  color: white;
  height: 2rem;
  width: 2rem;
}

.coloured-bg-icon-lbl {
  color: #c3ccc9;
}

.width-14 {
  width: 100%;
}

input[type='range'] {
  width: 100%;
  height: 12px;
  background: #ddd;
  border-radius: 5px;
  margin-top: 2rem;
}

.coloured-bg-green {
  color: #339b42;
}

.coloured-bg-lbl-green {
  background-color: #339b42;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-blue {
  color: #2a5f86;
}

.coloured-bg-lbl-blue {
  background-color: #2a5f86;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-grey {
  color: #6e6f6c;
}

.coloured-bg-lbl-grey {
  background-color: #6e6f6c;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-black {
  color: #1b1d1f;
}

.coloured-bg-lbl-black {
  background-color: #1b1d1f;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-red {
  color: #fd625e;
}

.coloured-bg-lbl-red {
  background-color: #fd625e;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-light-grey {
  color: #e1e4e5;
}

.coloured-bg-lbl-light-grey {
  background-color: #e1e4e5;
  color: white !important;
  width: auto;
  height: 2.5rem;
  border-radius: 5px;
}

.coloured-bg-value {
  font-size: 18px;
  font-weight: 900;
}

.coloured-bg-div {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.green-gradient-container {
  background: linear-gradient(to bottom, #069171, #024836);
  height: 10rem;
  border-radius: 1rem !important;
}

.gradient-container-button {
  background-color: #dff391 !important;
  color: #000000 !important;
  font-weight: 700 !important;
}

.icon-bg {
  border-radius: 100%;
  background-color: #145d92;
  color: white;
  height: 2rem;
  width: 2rem;
}

.coloured-bg-icon-lbl {
  color: #c3ccc9;
}

.width-14 {
  width: 100%;
}

input[type='range'] {
  width: 100%;
  height: 12px;
  background: #ddd;
  border-radius: 5px;
  margin-top: 2rem;
}

.bg-custom {
  font-size: 9pt !important;
  padding: 5px !important;
  font-weight: bold !important;
}

.notification-sidebar {
  width: 250px;
  /* min-height: 70vh;  */
  background-color: #e1e5e9;
}

.simulator-tbl-width {
  min-width: 100rem;
}

.table-row-height {
  max-height: 1rem !important;
}

.vl {
  border-left: 2px solid rgba(0, 0, 0, 0.075);
}

.small-unit-size {
  font-size: 12px !important;
  padding: 1px !important;
}

.width-5 {
  min-width: 5rem !important;
  max-width: 5rem !important;
}

.disable-bg {
  background-color: #a5a5ab !important;
}

.min-width-2 {
  min-width: 2rem !important;
}

.pool-vessel-card {
  min-height: 12rem;
  border-radius: 5px;
}

.simulator-analysis-heading {
  text-align: center;
  text-shadow:
    0px 0.2px,
    0.2px 0px;
  font-weight: bold;
  color: #069171;
}

.simulator-analysis-card-blue {
  background-color: #145d92;
  border-radius: 5px;
  color: white;
  width: auto;
}

.simulator-analysis-card-green {
  background-color: #339b42;
  border-radius: 5px;
  color: white;
  width: auto;
}

.simulator-analysis-card-turquoise {
  background-color: #069171;
  border-radius: 5px;
  color: white;
  width: auto;
}

.color-pink {
  color: #fd625e !important;
}

.color-blue {
  color: #0095ff;
}

.color-light-blue {
  color: #00fbff;
}

.mtop-6 {
  margin-top: 6rem;
}

.light-grey {
  background-color: #00000004 !important;
}

.center-vertically {
  display: flex;
  align-items: center;
}

.p {
  color: #504d4d;
}

.btn-vessel-configuration {
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.width-17 {
  width: 17rem !important;
}

.disabled-icon {
  color: #b0b0b0;
  pointer-events: none;
}

.eye {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.5rem;
}

.ml-55 {
  margin-left: 55rem;
}

.icon_wave {
  font-size: 26px;
  color: rgba(var(--icon-rgb, 0, 123, 255), 0.8);
  border-radius: 50%;
  padding: 5px;
  transition: all 0.3s ease;
}

.icon_wave:hover {
  background-color: #5156be;
  color: white !important;
}

.margin-top-0-8 {
  margin-top: 0.8rem;
}

.height-6-25 {
  height: 6.25rem;
}

.height-5 {
  height: 4rem;
}

.color-assign-user {
  color: #264e80;
}
